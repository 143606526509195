import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/app/src/layouts/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`活動目的`}</h2>
    <p>{`緩く繋がりながら`}<strong>{`メンバー同士サポートし合い、長く冒険を楽しむ`}</strong>{`こと。`}</p>
    <h2>{`運営方針`}</h2>
    <ul>
      <li parentName="ul">{`運営はボランティアと寄付で賄われます`}</li>
      <li parentName="ul">{`FCは多様なコンテンツに挑戦する場と冒険に必要な設備やツールを提供します`}</li>
      <li parentName="ul">{`ルールは必要最低限に、状況に応じて柔軟に変更します`}</li>
      <li parentName="ul">{`どのように楽しむかはメンバー各自の自発性に委ねられます`}</li>
      <li parentName="ul">{`FC及びメンバーのための行動を尊重し支援します`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      